import React, { useState } from "react";
import Header from "./components/header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import * as S from "./App.styles";
import Footer from "./components/footer/Footer";
import QRModal from "./components/qrModal/QRModal";
import Terms from "./pages/terms/Terms";
import PrivacyPolicy from "./pages/privacy/Privacy";
import Redirect from "./pages/redirect/Redirect";

function App() {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <BrowserRouter>
      <S.StyledContainer>
        <Header onModalOpen={openModal} />
        <Routes>
          <Route path="/" element={<Home onModalOpen={openModal} />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/redirect" element={<Redirect />} />
        </Routes>
      </S.StyledContainer>
      <Footer />
      <QRModal visible={modalIsOpen} closeModal={closeModal} />
    </BrowserRouter>
  );
}

export default App;
